export default [
  /* */
  { name: '捷運燙金實力', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  { name: '採買多元便利', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '',offset:-60,mobileOffset:0,  type: '' },
  { name: '健康綠意匯聚', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '',offset:-60,mobileOffset:0, type: '' },
  { name: '低密度大基地', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  //{ name: 'Pia美學巨擘', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  //{ name: 'MAG地標推手', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  // { name: '樣品屋預留', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '',offset:-60, mobileOffset:0 , type: '' },
  
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '',offset:-60,mobileOffset:0, type: 'btn' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '',offset:-60, mobileOffset:0, type: 'btn' },
  { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '',offset:-60, mobileOffset:0, type: 'btn' },
]
