import { isMobile } from '../utils/index'
export default {
  address: '新北市樹林區備內街1巷18號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.399469918906!2d121.41016911561155!3d24.98653864634494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34681d1abec431c7%3A0x1b1e52f4afca2fa1!2zMjM45paw5YyX5biC5qi55p6X5Y2A5YKZ5YWn6KGXMeW3tzE46Jmf!5e0!3m2!1szh-TW!2stw!4v1666158313084!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/iFMYsM2VXgb7zdXNA',
  phone: '02-7709-2200',
  fbLink: 'https://www.facebook.com/101486081795839/',
  fbMessage: 'https://m.me/101486081795839/',
  caseName: 'yjy',
  indigatorLength: 10,

  houseInfos: [
    ['投資建設', '溪福建設',],
    ['營造工程', '松銘營造',],
    ['建築設計', '沈伯卿建築師事務所',],
    ['使照號碼', '109樹使字第00126號',],
    ['使用分區', '乙種工業區',],
  ],

  gtmCode: ['P7JF8SV'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    // subTitle: '若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！
    room_type: ['兩房','三房'],//房型
  },
  lixin_logo:true, //false

}